<template>
  <div>
    <div class="login-page">
      <v-container>
        <v-row justify="center" class="mt-16">
          <v-col cols="12" lg="5">
            <v-card flat class="rounded-lg">
              <form @submit.prevent="initilizeCaptcha" v-if="!sms_sent">
                <v-card-text class="pa-6 pa-lg-12">
                  <h3 class="text-center mb-lg-8 mb-4">
                    Confirmation de votre Telephone
                  </h3>
                  <v-alert
                    dense
                    v-if="sendError"
                    color="red"
                    dark
                    icon="mdi-information-outline"
                    border="left"
                    prominent
                  >
                    {{ sendError }}
                  </v-alert>

                  <div
                    id="recaptcha-container"
                    :data-sitekey="getSiteKey"
                    data-callback="sendForm"
                    data-size="invisible"
                  ></div>

                  <v-text-field
                    outlined
                    prepend-inner-icon="mdi-phone"
                    type="text"
                    v-model="phone"
                    label="Numero de Telephone *"
                  >
                  </v-text-field>

                  <v-btn
                    type="submit"
                    depressed
                    :loading="btnLoading"
                    large
                    block
                    color="primary"
                    class="rounded-lg"
                  >
                    Envoyer un code
                  </v-btn>
                </v-card-text>
              </form>

              <form v-if="sms_sent" :disabled="checkLoading">
                <v-card-text class="pa-6 pa-lg-12 justify-center">
                  <h3 class="text-center mb-lg-8 mb-4">
                    Confirmation de votre Telephone
                  </h3>
                  <p class="d-flex justify-center">
                    <OtpInput
                      ref="otpInput"
                      input-classes="otp-input"
                      separator="-"
                      :num-inputs="6"
                      :should-auto-focus="true"
                      :is-input-num="true"
                      @on-change="handleOnChange"
                      @on-complete="handleOnComplete"
                    />
                  </p>
                  <p class="red--text d-flex justify-center">
                    {{ errors.phone ? errors.phone[0] : "" }}
                  </p>
                  <div class="d-flex justify-center">
                    <p class="red--text justify-center" v-if="code_error">
                      Code Incorrect <br />
                      <v-btn
                        @click="sms_sent = false"
                        text
                        color="gifty"
                        class="text-decoration-none mt-3"
                      >
                      Réenvoyer
                      </v-btn>
                    </p>
                  </div>

                  <p class="text-center">
                    <v-progress-circular
                      color="primary"
                      indeterminate
                      v-if="checkLoading"
                    >
                    </v-progress-circular>
                  </p>
                </v-card-text>
              </form>

              <v-card-text class="text-center mt-0 pt-0">
                <v-divider />

                <v-btn
                  @click="logout"
                  text
                  color="gifty"
                  class="text-decoration-none mt-3"
                >
                  Déconnecter
                </v-btn>
              </v-card-text>
            </v-card>
            <p class="text-center white--text mt-10">
              © Copyright {{ new Date().getFullYear() }}
              <strong class="text-primary">Gifty</strong> Tous droits réservés.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
// Import the functions you need from the SDKs you need
import { HTTP } from "@/http-common";

import OtpInput from "@bachdgvn/vue-otp-input";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  RecaptchaVerifier,
  GoogleAuthProvider,
  signInWithPhoneNumber,
} from "firebase/auth";


const firebaseConfig = {
  apiKey: process.env.VUE_APP_apiKey,
  authDomain: process.env.VUE_APP_authDomain,
  databaseURL: process.env.VUE_APP_databaseURL,
  projectId: process.env.VUE_APP_projectId,
  storageBucket: process.env.VUE_APP_storageBucket,
  messagingSenderId: process.env.VUE_APP_messagingSenderId,
  appId: process.env.VUE_APP_appId,
  measurementId: process.env.VUE_APP_measurementId,
};

var app;
var provider;
var auth;

try {
  // Initialize Firebase
  // Initialize Firebase
  app = initializeApp(firebaseConfig);
  provider = new GoogleAuthProvider();

  //window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {}, auth);
  auth = getAuth(app);
} catch (e) {
  console.log(e);
}

export default {
  data() {
    return {
      btnLoading: false,
      phone: this.$store.getters.getUser.user.phone,
      sms_sent: false,
      checkLoading: false,
      sendError: false,
      code_error: false,

      errors: {},
    };
  },
  components: { OtpInput },
  methods: {
    logout() {
      this.loading = true;
      HTTP.post("auth/logout")
        .then(() => {
          this.loading = false;
          this.$store.dispatch("user", "");
          this.$store.dispatch("isLoggedIn", false);
          this.$store.dispatch("stores", []);
          this.$router.go(0);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    getSiteKey() {
      return process.env.VUE_APP_recaptcha_sitekey;
    },
    handleOnComplete(value) {
      //console.log("OTP completed: ", value);
      this.checkCode(value);
    },
    handleOnChange(value) {
      //console.log("OTP changed: ", value);
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
    initilizeCaptcha() {
      this.btnLoading = true;
      this.sendError = null;
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            //console.log(response);
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            // ...
          },
          "expired-callback": () => {
            // Response expired. Ask user to solve reCAPTCHA again.
            // ...
          },
        },
        auth
      );

      const appVerifier = window.recaptchaVerifier;

      signInWithPhoneNumber(auth, this.phone, appVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;
          this.btnLoading = false;

          this.sms_sent = true;
          this.code_error = false;
        })
        .catch((error) => {
          this.btnLoading = false;

          if (
            error == "FirebaseError: Firebase: Error (auth/too-many-requests)."
          ) {
            this.sendError =
              "Trop de tentatives, veuillez réessayer ultérieurement ";
          }
        });
    },

    checkCode(code) {
      this.checkLoading = true;
      window.confirmationResult
        .confirm(code)
        .then((result) => {
          // User signed in successfully.
          const user = result.user;
          // ...
          console.log(user);
          this.checkLoading = false;

          this.phoneConfirmed(user.phoneNumber);
        })
        .catch((error) => {
          // User couldn't sign in (bad verification code?)
          // ...
          this.checkLoading = false;
          this.code_error = true;
        });
    },

    phoneConfirmed(phone) {
      HTTP.post("/v1/gifty/confirm_phone", {
        phone,
      }).then((res) => {
          //
          this.$store.dispatch("phoneConfirmed");
          this.$router.push("/");
        })
        .catch((err) => {
          console.log(err);
          this.errors = err.response.data.errors;
          this.$errorMessage = this.errors.phone
            ? this.errors.phone[0]
            : "Une erreur s'est produite.";

          if (this.errors.phone) {
            this.sms_sent = false;
          }
        });
    },
  },
  created() {
    //this.initilizeCaptcha();
  },
};
</script>

<style>
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  color: rgba(214, 1, 72);
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(214, 1, 72, 0.993);
  text-align: center;
  outline-color: rgba(214, 1, 72);
}
.otp-input:focus-visible {
  outline-color: rgba(214, 1, 72);
  border: 1px solid rgba(214, 1, 72, 0.993);
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
